<template>
<div class="main"  style="height: 100%;">
    <el-row>
  <el-col :span="24" align="middle">
    <el-dialog
  title="选择对应的超市"
  :visible="true"
  width="30%"
  :show-close="false"
  >
      
        <el-row>
  <el-col :span="24" align="middle"><div ><el-select v-model="value" @change="inMast" placeholder="请选择">
    <el-option
      v-for="item in marts"
      :key="item.label"
      :label="item.label"
      :value="item">
    </el-option>
  </el-select>
  </div></el-col>
</el-row>

    </el-dialog>
   
    </el-col>

</el-row>
 

</div>

</template>
<script>

import {PostobjKeySort,StrToDate}  from '../components/common/js/printHelp.js'
    import  md5 from '../components/common/js/allSchool.js'
    import Sidentify from '../components/common/identify'  //**引入验证码组件**
   import store  from '../store'
    import axios from 'axios'

    export default {

mounted(){
  var that=this
            that.init()
            },
methods:{
                 init(){  
                 
                     let url='http://api.thsen.top:15001/getip?cust_name=3333333333';
                     let ip=""
                     let par={cust_name:3333333333}
           
                this.$axios.get(url)
                .then(res => {   
                  this.$store.commit('getlocalIp',res.data.IP)                
                    
                    this.aftegetopnid(res.data.IP)

                    })                                 
               },
                aftegetopnid(sip){
   
                   let scode=  this.$route.query.code
                   if (scode!='tel')
                   {
                  let query={}

                  query= {
                     ip:sip,
                     code:scode,
                     isadmin: this.$route.query.state==="control"?'true':'false' //这里是判断是不是管理员
                   }
                 
                   let newkey = PostobjKeySort(query)
                  
                   let sign= md5.M5(newkey.md5s)
                    let url="/SCM/LoginByCode?sign="
                    this.reqM1Service(url+sign,newkey.par,'post')
                    
                    .then((res => {
                        console.log("使用二维码登入后,得到res",res.data)
                        let tmp_list=[];
                        let v=res.data.data
                        if(this.$route.query.state!='control'){
                          v.forEach(element => {
                          let date1=StrToDate(element.供应链有效期 ) ;
                          let date2=StrToDate(element.供应商有效期) ;
                          let  currentday=new Date();
                          if (date1>=currentday && date2>=currentday)
                          {
                            tmp_list.push(element)
                          }                         
                        });
                        }
                        else{
                            v.forEach(element => {
                          let date1=StrToDate(element.供应链有效期 ) ;
                          
                          let  currentday=new Date();
                          if (date1>=currentday )
                          {
                            tmp_list.push(element)
                          }                         
                        });
                        }
                        
                        console.log("tmplist",tmp_list)
                        this.beforeInmastorList(tmp_list)                               
                        this.$store.commit("setgyllist",tmp_list)  
                        this.$store.commit("setUserName",tmp_list[0].名字+"/"+tmp_list[0].供应链名称)                                                                                 
                    }                                   
                    ))
                   }
                   //如果是tel方式登入
                   else
                   {
                      this.beforeInmastorList(this.$store.state.gyllist)
                   }
                   
                  },
///做登入前的准备工作
            beforeInmastorList(datas){
              console.log("datas ",datas)
                          this.marts=[]
                          if (this.$route.query.state==="control")
                          {
                          datas.forEach(element => {
                          this.marts.push({ value:element.供应链编码,
                          label:element.供应链名称,
                          state:element.供应链状态,
                          end:element.供应链有效期,
                          userName:element.名字,
                          supportName:element.供应商名称,
                          unionid:element.unionid

                          }                          
                          )                                                  
                        });
                          }
                          ////供应商状态下
                          else{
                          datas.forEach(element => {
                          this.marts.push({ value:element.供应链编码,
                          label:element.供应链名称+"-"+element.供应商编码,
                          state:element.供应链状态,
                          end:element.供应链有效期,
                          superCode:element.供应商编码,
                          gysend:element.供应商有效期,
                          gysName:element.供应商名字,
                          unionid:element.unionid,
                          userName:element.名字,
                          }                          
                          )                                                  
                        });
                          }
                        ////如果超市的列表是1,就不用选择直接计入下个页面
                        if(this.marts.length===1)
                        {
                          this.value.value=this.marts[0].value
                          this.inMast();
                        }
            },

                  ///下拉窗口是值发生改变时
                  inMast(){
                   
                    let obj = {};
                    obj = this.marts.find((item)=>{//这里的selectList就是上面遍历的数据源
                    console.log("item,",item,this.value)
                    if(item.value === this.value.value && item.superCode===this.value.superCode)
                    {
                       return  true;//筛选出匹配数据
                    }
                   
                  });
                  
                   console.log("obj is ",obj)
                  if(this.$route.query.state==="control")
                  {
                      let v={
                      unionid:obj.unionid,
                      ip:this.$store.state.ip,
                      martName:obj.label,
                      martId:obj.value,
                      userName:obj.userName                    
                    }
                    this.$store.commit("control_Login",v)
                            
                    this.$router.push("./main_control")

                  }
                  else{
                   
                      let v={
                      unionid:obj.unionid,
                      ip:this.$store.state.ip,
                      martName:obj.label,
                      martId:obj.value,
                      superCode:obj.superCode,
                      superName:obj.gysName,
                      userName:obj.userName

                    }
                    this.$store.commit("newLogin",v)
                   console.log("username is " ,this.$store.state.userName,"supcustcode is ",this.$store.state,)                  
                    this.$router.push("./main")
                  }
                   
                  }


                 },

        data: function() {
            return {
                value: '',
                marts:[]
            }}}
</script>
<style >
.main{
  background: slategrey;
}

</style>
